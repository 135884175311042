// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "s_sD d_gS d_cw d_dv";
export var quoteParagraphCenter = "s_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "s_gV d_gV d_cw d_dx";
export var quoteRowLeft = "s_sF d_bG";
export var quoteRowCenter = "s_sG d_bD";
export var quoteRowRight = "s_sH d_bH";
export var quoteWrapper = "s_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "s_gR d_gR";
export var quoteExceptionSmall = "s_rN q_rN";
export var quoteExceptionNormal = "s_rP q_rP";
export var quoteExceptionLarge = "s_rQ q_rQ";
export var quoteAuthorExceptionSmall = "s_rR q_rR";
export var quoteAuthorExceptionNormal = "s_rS q_rS";
export var quoteAuthorExceptionLarge = "s_rT q_rT";